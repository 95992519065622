<template>
    <span class="time">{{time}}</span>
</template>

<script>
import '@/styles/style.css';
export default {

  data() {
    return {
      time: '',
      clockTimer: null
    }
  },
  props: ["startTime", "endTime"],
  mounted() {
    // this.clockTimer = setInterval(() => this.getRemainTime(), 1000)
  },
  methods: {
    getRemainTime () {
      const now = new Date()
      const endDate = new Date(this.endTime)
      const startDate = new Date(this.startTime)
      const timeRemaining = endDate - now
      const timeBeforeStart = now - startDate
      if (timeRemaining <= 0 || timeBeforeStart <=0) {
        console.log('방송종료')
    	  this.time = "방송종료";
        clearInterval(this.clockTimer)
        this.$emit("submitLeave");
      	return;
      }
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    },
    startOnairTimer() {
      this.clockTimer = setInterval(() => this.getRemainTime(), 1000)
    }
  }
}
</script>