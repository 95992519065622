<template>
	<div class="pop-livetalk-header">
    <h1>홈앤 &amp;tok</h1><!-- 2024-10-22 수정 -->
		<!--<span class="time">00:38:53</span>-->
    <clock ref="remainClock" :startTime="startTime" :endTime="endTime" @submitLeave="leaveChat()"></clock>
		<!-- <a href="#none" class="btn-close">닫기</a>-->
    <a href="hnsmallapp://webcontrol?action=typeViewClose" class="btn-close">X</a>
	</div>
</template>

<script>
import clock from '@/components/RemainClock'
import '@/styles/style.css';
export default {
  components: {
    clock,
  },
  data() {
    return {
      playingTime : "",
    }
  },
  methods: {
    leaveChat() {
      console.log("leave")
      //window.location.href = "hnsmallapp://webcontrol?action=typeViewClose"
      window.open("hnsmallapp://webcontrol?action=typeViewClose", "_self");
    },
    startOnairTimer(){
      this.$refs.remainClock.startOnairTimer()
    }

  },
  props: ["startTime","endTime"]
}
</script>